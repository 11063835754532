<template>
  <div id="CONTENT">
    <tagline :sitename="sitename" :title="title"></tagline>
    <navidiv></navidiv>
    <!-- H1 -->
    <div class="h1-binder">
      <h1 class="b1">{{ title }}</h1>
      <!-- 検索フォーム -->
      <form method="get" action="/annotations/search" id="SEARCH-FORM" name="Search">
        <input type="text" value="" name="keywords" class="keyword" /><input type="image" src="/img/btn_search.gif" value="" alt="検索" name="" class="submit" />
      </form>
      <!-- /検索フォーム -->
    </div>
    <!-- /H1 -->
    <!-- パンくず -->
    <div id="TOPIC-PATH"><a href="http://ebiki.jp/">トップページ</a> &gt; <a href="/">絵引データベース</a> &gt; <a :href="'/motifs/view/' + this.motif_id">{{ motif.label }}</a></div>
    <!-- /パンくず -->
    <!-- コンテンツ -->
    <div id="CONTENT-BODY">
      <!-- H2 -->
      <div class="h2-binder">
        <h2>{{ motif.label }}（{{ motif.label_kana }}）</h2>
        <p class="date">公開日:{{ motif.published | display_date | moment("YYYY/MM/DD") }}　最終更新日:{{ motif.modified | display_date | moment("YYYY/MM/DD") }}</p>
      </div>
      <!-- /H2 -->
			<!-- 説明文 -->
			<div class="main-description" v-html="motif.description"></div>
      <div class="other-layers">
        <p>｜他のカテゴリー</p>
        <ul>
          <li v-for="(layer, index) in other_layers" :key="index"><router-link :to="{ path: '/motifs/view/' + layer.id}">{{ layer.caption }}</router-link></li>
        </ul>
      </div>
			<!-- /説明文 -->
      <!-- H3 -->
      <h3>図像・事物一覧</h3>
      <!-- /H3 -->
      <!-- 表示制御 -->
      <div class="controller">
        <!-- ページ送り -->
        <div class="paginate"><paginate-links for="annotations" :show-step-links="true"></paginate-links></div>
        <!-- /ページ送り -->
        <!-- 表示件数 -->
        <ul class="limit">
          <li :class="[this.limit == 16 ? 'selected' : '']"><router-link :to="{ path: '/motifs/view/' + this.motif_id, query: {limit: 16}}">表示数 16</router-link></li>
          <li :class="[this.limit == 32 ? 'selected' : '']"><router-link :to="{ path: '/motifs/view/' + this.motif_id, query: {limit: 32}}">表示数 32</router-link></li>
          <li :class="[this.limit == 64 ? 'selected' : '']"><router-link :to="{ path: '/motifs/view/' + this.motif_id, query: {limit: 64}}">表示数 64</router-link></li>
          <li :class="[this.limit == 128 ? 'selected' : '']"><router-link :to="{ path: '/motifs/view/' + this.motif_id, query: {limit: 128}}">表示数 128</router-link></li>
        </ul>
        <!-- /表示件数 -->
        <!-- 総数 -->
        <p class="total">全{{ total }}件</p>
        <!-- /総数 -->
      </div>
      <!-- /表示制御 -->
			<!-- 一覧 -->
        <paginate name="annotations" :list="thumbnails" :per="limit" :tag="'table'" :class="'base-skin img-list'">
          <template v-for="(tr, index) in list4table(paginated('annotations'))">
            <tr :key="index">
              <td v-for="n in 8" :key="n">
                <template v-if="tr[n-1]">
                  <router-link :to="{ path: '/annotations/view/' + tr[n-1].id}"><img :src="tr[n-1].image" width="70" height="70" :alt="tr[n-1].alt" :title="tr[n-1].alt" /></router-link>
                </template>
                <template v-else>
                  <img src="/img/spacer.gif" width="70" height="70" alt="" />
                </template>
              </td>
            </tr>
          </template>
        </paginate>
			<!-- 一覧 -->
      <!-- 表示制御 -->
      <div class="controller">
        <!-- ページ送り -->
        <div class="paginate"><paginate-links for="annotations" :show-step-links="true"></paginate-links></div>
        <!-- /ページ送り -->
        <!-- 表示件数 -->
        <ul class="limit">
          <li :class="[this.limit == 16 ? 'selected' : '']"><router-link :to="{ path: '/motifs/view/' + this.motif_id, query: {limit: 16}}">表示数 16</router-link></li>
          <li :class="[this.limit == 32 ? 'selected' : '']"><router-link :to="{ path: '/motifs/view/' + this.motif_id, query: {limit: 32}}">表示数 32</router-link></li>
          <li :class="[this.limit == 64 ? 'selected' : '']"><router-link :to="{ path: '/motifs/view/' + this.motif_id, query: {limit: 64}}">表示数 64</router-link></li>
          <li :class="[this.limit == 128 ? 'selected' : '']"><router-link :to="{ path: '/motifs/view/' + this.motif_id, query: {limit: 128}}">表示数 128</router-link></li>
        </ul>
        <!-- /表示件数 -->
        <!-- 総数 -->
        <p class="total">全{{ total }}件</p>
        <!-- /総数 -->
      </div>
      <!-- /表示制御 -->
    </div>
    <!-- /コンテンツ -->
    <footerdiv></footerdiv>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { Backend } from '@/ajax/Backend'
const iiifLayer = Backend.get('layer')
const iiifAnnotationList = Backend.get('list')

// @ is an alias to /src
import tagline from '@/components/tagline.vue'
import navidiv from '@/components/navi.vue'
import footerdiv from '@/components/footer.vue'

import $ from 'jquery'
import ebikiMixin from '@/mixins/ebikiMixin.js'

export default {
  name: 'motif',
  mixins: [ ebikiMixin ],
  components: {
    tagline,
    navidiv,
    footerdiv
  },
  props: {
    motif_id: { type: Number, required: true },
    sitename: { type: String, required: true },
    orgname: { type: String, required: true },
    manifest:  { type: Object, required: true },
    manifest_id:  { type: Number, required: true },
    title:  { type: String, required: true },
    meta_description:  { type: String, required: true },
    meta_keywords:  { type: String, required: true },
  },
  data: function () {
    return {
      limit: 32,
      paginate: ['annotations'],
      layer: {},
      annotations: [],
    };
  },
  filters: {
    display_date: function(value) {
      return value ? value : '0000/00/00'
    }
  },
  computed: {
    motif: function() {
      return {
        label: this.layer.label,
        description: this.layer.description,
        label_kana: this.findMetadata(this.layer.metadata, "name_kana"),
        published: this.findMetadata(this.layer.metadata, "published"),
        modified: this.findMetadata(this.layer.metadata, "modified")
      }
    },
    thumbnails: function() {
      let value = []
      if (this.annotations) {
        this.annotations.forEach((_, i) => {
          value.push({
            id: this.annotations[i]['@id'].match(/\d+$/)[0],
            image: this.annotations[i].thumbnail['@id'],
            name_kana: this.findMetadata(this.annotations[i].metadata, "name_kana"),
            alt: this.annotations[i].label
          })
        });
        if (value) {
          // 並び替え
          value.sort((a, b) => {
            a = a['name_kana']
            b = b['name_kana']
            return (a === b ? 0 : a > b ? 1 : -1)
          });
        }
      }
      return value
    },
    other_layers: function() {
      // 他のカテゴリー
      let layers = []
      if (this.manifest.structures) {
        const members = this.manifest.structures[0].members
        members.forEach((_, i) => {
          let layer = {}
          layer.id = members[i].contentLayer.match(/\d+$/)[0]
          layer.caption = members[i].label
          if (layer.id != this.motif_id) {
            layers.push(layer)
          }
        });
      }
      return layers
    },
    total: function() {
      return this.thumbnails.length
    },
  },
  watch: {
    $route: {
      handler: function() {
        this.limit = (this.$route.query.limit ? Number(this.$route.query.limit) : 32)
      },
      immediate: true,
      deep: true
    },
    limit: function() {
      $('.select-collapsed p').text('表示数 '+this.limit)
    }
  },
  beforeRouteUpdate (to, from, next) {
    // 他のカテゴリーに遷移する際に図像・事物一覧を更新するためリダイレクトする
    if (to.params.id != from.params.id) {
      document.location = '/motifs/view/'+to.params.id
    }
    next()
  },
  mounted: async function() {
    // APIの読み込み
    this.layer = await this.getLayer(this.manifest_id, this.motif_id)
    if (this.layer.otherContent) {
      this.layer.otherContent.forEach(async (_, i) => {
        let annotationlist
        // アノテーションリストAPIの読み込み
        annotationlist = await this.getAnnotationList(this.layer.otherContent[i])
        if (annotationlist.resources) {
          annotationlist.resources.forEach(async (_, j) => {
            this.annotations.push(annotationlist.resources[j])
          });
        }
      });
    }
  },
  methods: {
    // レイヤーAPIの読み込み
    getLayer: function(manifest_id, layer_id) {
      return iiifLayer.get(manifest_id, layer_id)
        .then(response => {return response.data})
        .catch(err => console.log(err))
    },
    // アノテーションリストAPIの読み込み
    getAnnotationList: function(url) {
      return iiifAnnotationList.getByURL(url)
        .then(response => {return response.data})
        .catch(err => console.log(err))
    }
  },
  head: {
    title: function () {
      return {
        inner: this.sitename + ' ' + this.title + ' - ' + this.orgname,
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.meta_description },
        { name: 'keywords', content: this.meta_keywords },
        { property: 'og:title', content: this.sitename + ' ' + this.title + ' - ' + this.orgname },
        { property: 'og:description', content: this.meta_description },
      ]
    }
  }
}
</script>
